.platform-right-container {
    background:  #2b2549;
    background: linear-gradient(13deg, #201b38 0%, #241f3e 60%, #1e1936 100%);
    height: 100vh;
    width: 88%;
    position:relative;
    z-index: 80;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.progress-intro-box {
    width: 100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.progress-intro-box span {
    display:flex;
    flex-direction: row;
    align-items: center;

}
.progress-intro-box img {
    width: 50px;
    border-radius: 10px;
    height: 50px;

}
.progress-intro-box section {
    width: 50%;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    gap:15px;
}
.progress-intro-box h5 {
    color: rgb(228, 228, 228);
    padding: 0px;
    font-weight: 700;
    width:100%;
    font-size: 2rem;
    text-align: center;
}
.progress-intro-box p {
    color: rgb(234, 234, 234);
    padding: 0px;
    font-weight: 300;
    width:100%;
    font-size: .8rem;
    text-align: center;
}
.progress-intro-box div {
    display:flex;
    min-width: 100px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background:  #312954;
    border-radius: 15px;
    padding:15px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

}
.platform-right-container h1 {
    color: rgb(237, 237, 237);
    padding: 60px 30px;
    font-weight: 500;

    font-size: 2rem;
}
.platform-right-container h3 {
    color: rgb(159, 159, 159);
    padding: 0px 10px 10px 30px;
    font-weight: 400;
    width:100%;
    font-size: 1rem;
}
.platform-right-container h2 {
    color: rgb(159, 159, 159);
    padding: 10px 10px 0px 30px;
    font-weight: 400;
    width:100%;
    font-size: 1.3rem;
}
.progress-container {
    width: 98%;
    min-height: 97px;
    border-radius: 15px;
    position: relative;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background:  #2b2549;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}
.progress-bar {
    width: 92%;
    height: 15px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.494);
    border-radius: 15px;
    margin: 10px 0 0 0;
}
.progress-focus {
    background-color: rgb(104, 174, 255);
    height: 100%;
    width: 0%;
    border-radius: 15px;
    transition: width .5s;
}
.progress-1 {
    animation: progress1 1s forwards;
}
.progress-2 {
    animation: progress2 1s forwards;
}
.progress-3 {
    animation: progress3 1s forwards;
}
.progress-4 {
    animation: progress4 1s forwards;
}

@keyframes progress1 {
    0% {
        width: 0%;
    }
    100% {
        width: 25%;
    }
}
@keyframes progress2 {
    0% {
        width: 0%;
    }
    100% {
        width: 50%;
    }
}
@keyframes progress3 {
    0% {
        width: 0%;
    }
    100% {
        width: 75%;
    }
}
@keyframes progress4 {
    0% {
        width: 0%;
    }
    100% {
        width: 100%;
    }
}
.progress-item {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgb(89, 149, 216);
    position:absolute;
    top: 23px;
    display:flex;
    justify-content: center;
    align-items: center;
}
.progress-item:hover {
    background-color: rgb(37, 128, 233);
    cursor: pointer;
}

.progress-item:nth-child(2) {
    left: 24%;
}
.progress-item:nth-child(3) {
    left: 47%;
}
.progress-item:nth-child(4) {
    right: 23%;
}
.progress-item:nth-child(5) {
    right: 2%;
}

.progress-item .svg-inline--fa {
    color: rgb(255, 255, 255);
    height: 1.7rem;
    position:relative;
}
.progress-text {
    width:100%;
    display:flex;
    background:  #2e2850;
    height: 60%;
    margin-top: 40px;
    position: relative;
    /*top box shadow*/
    box-shadow: 0 -10px 10px 0 rgba(0, 0, 0, 0.05);
    
}
.progress-text-container section {
    animation: in 1s;
}
.progress-text-container section {
    width:100%;
    height: 100%;
    display:flex;
    flex-direction: column;

    padding: 50px 40px 40px 40px;
}
.progress-text-container h2 {
    color: rgb(226, 226, 226);
    padding: 0px 0px 10px 0px;
    font-weight: 400;
    width:100%;
    font-size: 1.2rem;
}
.progress-text-container p {
    color: rgb(215, 215, 215);
    padding: 0px 0px 10px 0px;
    font-weight: 400;
    width:100%;
    line-height: 1.7rem;
    font-size: 1rem;
}
.progress-text-container button {
    width: 20%;
    min-width: 200px;
    align-self: flex-end;
    height: 60px;
    border-radius: 15px;
    background-color: rgb(104, 174, 255);
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: 1rem;
    border: none;
    margin-top: 50px;
    display:flex;
    justify-content: center;
    align-items: center;
    gap:25px;
}
.progress-text-container button:hover {
    background-color: rgb(37, 128, 233);
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.288);
}
.top-pointer {
    width: 50px;
    height: 50px;
    background:  #2e2850;
    box-shadow: -10px -10px 10px 0 rgba(0, 0, 0, 0.064);
    position:absolute;
    top: -25px;
    transform: rotate(45deg);
    transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.5s;
    
}
.progress-session-container {
    width:95%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    border-radius: 15px;
    background:  #2b2549;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.097);

}
.progress-session-container span {
    color: rgb(159, 159, 159);
    text-align: center;
    padding: 15px 0;
    font-weight: 400;
    font-size: .8rem;
    display:flex;
    flex-direction: column;
}
.progress-session-container span button {
    margin-top: 10px;
    padding: 5px 10px;
    align-self: center;
    outline: none;
    border: none;
    font-size: .8rem;
    color: #fafafa;
    border-radius: 15px;
    background-color: rgb(255, 158, 74);
}
.progress-session-container span button:hover {
    background-color: rgb(233, 125, 37);
    cursor: pointer;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.288);
}

.progress-session-container-title {
    width:100%;
    display:flex;
    justify-content: space-between;
}
.progress-session-container-title button{
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.38);
    color: rgb(255, 255, 255);
    font-weight: 500;
    font-size: .7rem;
    border: none;
    display:flex;
    justify-content: center;
    align-items: center;
    white-space:nowrap;
    padding: 0px 15px;
    margin: 5px 2.5% 10px 0px
}
.progress-session-container-title button:hover{
    background-color: rgb(47, 44, 64);
    cursor: pointer;
}

.progress-scroll-container {
    width:100%;
    overflow-y: scroll;
    width: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
@keyframes in {
    0% {
        opacity: 0;
        transform: translateY(10px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}
