
.space {
    position: absolute;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    background: #213766;
    overflow: hidden;
}
.astronaut-frame {
    position: relative;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 12;
}
.astronaut {
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: 12;
    transform: translate3d(0, 0, 0);
    animation: astronaut 3s ease-in-out infinite;
}
.astronaut > .head {
    position: absolute;
    width: 85px;
    height: 85px;
    border-radius: 50%;
    top: 70px;
    left: 60px;
    background: #ebeef7;
}
.astronaut > .head > .face {
    position: absolute;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: #a1d2d3;
    margin: 15px;
    overflow: hidden;
    z-index: 999;
}
.astronaut > .head > .face:before {
    content: '';
    position: absolute;
    background: #c3e3e7;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    margin-left: -5px;
}
.astronaut > .head > .face .eye {
    position: absolute;
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background: #213766;
    top: 24px;
}
.astronaut > .head > .face .eye.left {
    left: 15px;
    animation: eye-blink 10s infinite;
}
.astronaut > .head > .face .eye.right {
    left: 35px;
    animation: eye-blink 10s infinite;
}
.astronaut > .head > .face .mouth {
    position: absolute;
    width: 15px;
    height: 10px;
    top: 30px;
    left: 20px;
    overflow: hidden;
}
.astronaut > .head > .face .mouth:before {
    position: absolute;
    content: '';
    width: 10px;
    height: 10px;
    border: 2px solid #213766;
    border-radius: 50%;
    top: -8px;
}
.astronaut > .head > .face .cheek {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #a8d4d5;
    top: 30px;
}
.astronaut > .head > .face .cheek.left {
    left: 7.5px;
}
.astronaut > .head > .face .cheek.right {
    left: 40px;
}
.astronaut > .ear {
    position: absolute;
    width: 15px;
    height: 24px;
    background: #d1d3d7;
    top: 100px;
}
.astronaut > .ear.left {
    left: 52.5px;
    border-radius: 5px 0px 0px 5px;
}
.astronaut > .ear.left > .antenna {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: #d1d3d7;
    margin-top: -32px;
    margin-left: 1.4px;
}
.astronaut > .ear.left:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 25px;
    background: #d1d3d7;
    margin-top: -25px;
    margin-left: 4px;
}
.astronaut > .ear.right {
    left: 137.5px;
    border-radius: 0px 5px 5px 0px;
}
.astronaut .backpack {
    position: absolute;
    width: 94px;
    height: 80px;
    background: #ebeef7;
    top: 135px;
    left: 55px;
    border-radius: 5px;
}
.astronaut .backpack:before {
    position: absolute;
    content: '';
    width: 84px;
    height: 80px;
    border-radius: 5px;
    margin: 5px;
    background: #b9bcc2;
}
.astronaut .body {
    position: absolute;
    width: 110px;
    height: 100px;
    border-radius: 30% 30% 0px 0px;
    left: 45px;
    top: 140px;
    background: #ebeef7;
}
.astronaut .body > .shadow {
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-top: -13px;
    left: 5px;
}
.astronaut .body > .shadow:after {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #959698;
    left: 12.5px;
    top: -45px;
}
.astronaut .body > .shadow:before {
    content: '';
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #b9bcc2;
    left: 12.5px;
    top: -40px;
}
.astronaut .body .strap {
    position: absolute;
    width: 8px;
    height: 35px;
    background: #474a4b;
    border-radius: 5px;
    z-index: 999;
}
.astronaut .body .strap.left {
    left: 12px;
}
.astronaut .body .strap.right {
    left: 92px;
}
.astronaut .body .strap:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 40px;
    background: #d1d3d7;
    top: 35px;
    left: 2px;
}
.astronaut .body .chest {
    position: absolute;
    width: 50px;
    height: 80px;
    background: #dbdee0;
    top: 35px;
    left: 30px;
    border-radius: 10px;
}
.astronaut .body .chest:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    right: 7px;
    top: 7px;
    background: #f14b2e;
}
.astronaut .body .chest:after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border-radius: 50%;
    right: 7px;
    top: 16px;
    background: #b2b7bb;
}
.moon {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: #92b5e7;
}
.moon:before {
    position: absolute;
    content: '';
    background: #a9c7ff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    left: -10px;
    top: -10px;
}
.moon > .crater {
    background: #90b6ff;
    border-radius: 50%;
    position: absolute;
}
.moon > .crater.crater-1 {
    width: 20px;
    height: 20px;
    left: 12px;
    top: 26px;
}
.moon > .crater.crater-2 {
    width: 5px;
    height: 5px;
    left: 37px;
    top: 31px;
}
.moon > .crater.crater-3 {
    width: 8px;
    height: 8px;
    left: 42px;
    top: 41px;
    background: #7da7e2;
}
.cloud {
    position: absolute;
}
.cloud .part {
    position: absolute;
    border-radius: 4px;
    background: #7093dd;
}
.cloud.cloud-1 {
    width: 55px;
    height: 40px;
    margin-top: 120px;
    z-index: 99;
    animation: cloud-1 1s ease-in-out infinite;
}
.cloud.cloud-1 .part-1 {
    width: 35px;
    height: 8px;
}
.cloud.cloud-1 .part-1:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #7093dd;
    left: -16px;
}
.cloud.cloud-1 .part-2 {
    width: 22px;
    height: 6px;
    top: 8px;
    left: 6px;
}
.cloud.cloud-1 .part-2:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    background: #213766;
    left: 0px;
    border-radius: 50%;
}
.cloud.cloud-1 .part-2:after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    background: #213766;
    border-radius: 50%;
    left: 18px;
}
.cloud.cloud-1 .part-3 {
    width: 55px;
    height: 8px;
    top: 14px;
    left: -10px;
}
.cloud.cloud-2 {
    width: 80px;
    height: 66px;
    z-index: 10;
    margin-left: 130px;
    margin-top: 54px;
    animation: cloud-2 ease-in-out 1s 0.5s infinite;
}
.cloud.cloud-2 .part-1 {
    width: 35px;
    height: 6px;
    left: 35px;
}
.cloud.cloud-2 .part-1:before {
    content: '';
    position: absolute;
    background: #7093dd;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-left: 40px;
}
.cloud.cloud-2 .part-2 {
    width: 40px;
    height: 8px;
    margin-top: 18px;
    left: 33px;
}
.cloud.cloud-2 .part-3 {
    width: 28px;
    height: 8px;
    margin-top: 26px;
    left: 38px;
}
.cloud.cloud-2 .part-3:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background: #213766;
    left: 0px;
    border-radius: 50%;
}
.cloud.cloud-2 .part-3:after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background: #213766;
    border-radius: 50%;
    left: 23px;
}
.cloud.cloud-2 .part-4 {
    width: 70px;
    height: 8px;
    margin-top: 34px;
    left: 5px;
}
.cloud.cloud-2 .part-5 {
    width: 28px;
    height: 8px;
    margin-top: 42px;
    left: 10px;
}
.cloud.cloud-2 .part-5:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background: #213766;
    left: 0px;
    border-radius: 50%;
}
.cloud.cloud-2 .part-5:after {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background: #213766;
    border-radius: 50%;
    left: 23px;
}
.cloud.cloud-2 .part-6 {
    width: 45px;
    height: 8px;
    margin-top: 50px;
    left: 5px;
}
.cloud.cloud-2 .part-6:before {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    background: #7093dd;
    left: 50px;
    margin-top: 1px;
    border-radius: 50%;
}
.star {
    position: absolute;
    background: #82a9fd;
    border-radius: 50%;
    animation: blink 1.5s ease-in-out infinite;
}
.star:before {
    content: '';
    position: absolute;
    background: #82a9fd;
    border-radius: 50%;
}
.star.star-1 {
    width: 4px;
    height: 4px;
    background: #3a61b3;
    border-radius: 5px;
    top: 75px;
    left: 17px;
    animation-delay: 0.5s;
}
.star.star-2 {
    width: 7px;
    height: 3px;
    top: 30px;
    left: 70px;
    animation-delay: 1s;
}
.star.star-2:before {
    width: 3px;
    height: 7px;
    margin-top: -2px;
    margin-left: 2px;
}
.star.star-3 {
    width: 3px;
    height: 1px;
    top: 60px;
    left: 65px;
    animation-delay: 0.8s;
}
.star.star-3:before {
    width: 1px;
    height: 3px;
    margin-top: -1px;
    margin-left: 1px;
}
.star.star-4 {
    width: 7px;
    height: 3px;
    top: 100px;
    left: 30px;
    animation-delay: 1.2s;
}
.star.star-4:before {
    width: 3px;
    height: 7px;
    margin-top: -2px;
    margin-left: 2px;
}
.star.star-5 {
    width: 7px;
    height: 3px;
    top: 40px;
    left: 130px;
    animation-delay: 0.5s;
}
.star.star-5:before {
    width: 3px;
    height: 7px;
    margin-top: -2px;
    margin-left: 2px;
}
.star.star-6 {
    width: 3px;
    height: 1px;
    top: 30px;
    left: 150px;
    animation-delay: 1s;
}
.star.star-6:before {
    width: 1px;
    height: 3px;
    margin-top: -1px;
    margin-left: 1px;
}
.star.star-7 {
    width: 3px;
    height: 1px;
    top: 60px;
    left: 150px;
    animation-delay: 1.5s;
}
.star.star-7:before {
    width: 1px;
    height: 3px;
    margin-top: -1px;
    margin-left: 1px;
}
@keyframes eye-blink {
    0% {
        height: 4px;
        margin-top: 0px;
   }
    5% {
        height: 1px;
        margin-top: 2px;
   }
    10% {
        height: 4px;
        margin-top: 0px;
   }
}
@keyframes blink {
    0% {
        opacity: 0;
   }
    20% {
        opacity: 1;
   }
}
@keyframes cloud-1 {
    0% {
        transform: translateX(0px);
   }
    50% {
        transform: translateX(5px);
   }
    100% {
        transform: translateX(0px);
   }
}
@keyframes cloud-2 {
    0% {
        transform: translateX(0px);
   }
    50% {
        transform: translateX(-5px);
   }
    100% {
        transform: translateX(0px);
   }
}
@keyframes astronaut {
    0% {
        transform: translateY(0px);
   }
    50% {
        transform: translateY(-10px);
   }
    100% {
        transform: translateY(0px);
   }
}
.title, .subtitle {
    width: 100%;
    font-family: "Courier New", Courier, monospace;
    text-align: center;
}
.title {
    font-size: 30px;
    margin-top: 5%;
    color: gray;
}
a {
    text-decoration: none;
    color: #7093dd;
}



.arm-left{
    animation: armSwipe 5s 1s infinite ease-in-out;
    transform-origin: left top;
    }
    @keyframes armSwipe{
      0%{transform: rotate(-10deg);}
      50%{transform: rotate(0deg);}
        100%{transform: rotate(-10deg);}
    }
    .arm-right{
      animation: armRightSwipe 5s 1s infinite ease-in-out;
      transform-origin: right top;
    }
    @keyframes armRightSwipe {
      0%{transform: rotate(-1deg);}
      50%{transform: rotate(-5deg);}
        100%{transform: rotate(-1deg);}
    }
    .astronaut2{
      width: 40%;
      margin-bottom: 5%;
      height: auto;
      overflow: visible;
      animation: Levitation 5s 1s infinite ease-in-out;
    }
    @keyframes Levitation{
      0%{transform: translateY(0px);}
      50%{transform: translateY(30px); }
      100%{transform: translateY(0px);}
    }
    .leg-right{
      animation: LegSwipe 5s 1s infinite ease-out;
      transform-origin: left bottom;
    }
    @keyframes LegSwipe {
      0%{transform: rotate(8deg);}
      50%{transform: rotate(5deg);}
      100%{transform: rotate(8deg);}
    }
    .leg-left{
    
    }
    @keyframes LegLeftSwipe {
      0%{transform: rotate(2deg);}
      50%{transform: rotate(0deg);}
      100%{transform: rotate(2deg);}
    }
    .reflect{
      animation: faceOpacity 5s 1s infinite;
    }
    @keyframes faceOpacity {
      0%{opacity: 0.7;}
      70%{opacity: 1;}
      100%{opacity: 0.7;}
    }
    .eye-right{
      animation: eyeBlink 5s infinite step-start 0s;
    }
    .eye-left{
      animation: eyeLeftBlink 5s infinite step-start 0s;
    
    }
    @keyframes eyeBlink {
      90%{transform: none; animation-timing-function: ease-in;}
      93%{transform: translateY(135px) scaleY(0);}
      100%{animation-timing-function: ease-in;}
    }
    @keyframes eyeLeftBlink {
      90%{transform: none; animation-timing-function: ease-in;}
      93%{transform: translateY(155px) scaleY(0);}
      100%{animation-timing-function: ease-in;}
    }
    .stars{
      width: 800px;
      fill:#fff;
      overflow: visible;
      animation: starsRotate 155s 1s linear;
      transform-origin: center;
    }
    @keyframes starsRotate {
      0%{transform: rotate(0deg);}
      100%{transform: rotate(360deg);}
    }
    