.sign-popup-container {
    width:90%;
    height: 70%;
    z-index: 1010;
    animation: rocketup .5s;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

}

.sign-in-container h1 {
    color: #3f3f3f;
    font-size: 2.4rem;
    font-weight: 700;
    margin:40px 0 30px 0 ;

}
.sign-up-container h1 {
    color: #3f3f3f;
    font-size: 2.4rem;
    font-weight: 700;
    margin:40px 0 0px 0 ;
}
.sign-in-container{
    width: 40%;
    height:70%;
    background-color: rgb(225, 225, 225);
    position:absolute;
    left:10%;
    border-radius: 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 5px 5px 30px 0px rgb(37, 37, 37);

}
.sign-in-container form, .sign-up-container form {
    display:flex;
    flex-direction: column;
    width: 80%;
}
.sign-in-container label {
    color: #515151;
    font-size: 1rem;
    font-weight: 300;
    margin:25px 0 0 0 ;
}
.sign-up-container label {
    color: #515151;
    font-size: 1rem;
    font-weight: 300;
    margin:15px 0 0 0 ;
}
.sign-in-container input {
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid #8f8f8f;
    background-color: rgb(195, 195, 195);
    color: #424242;
    font-size: 1rem;
    font-weight: 300;
    margin:5px 0 0 0 ;
    outline: none;
    padding: 25px 10px;
}
.sign-up-container input {
    width: 100%;
    height: 2rem;
    border-radius: 5px;
    border: 1px solid #8f8f8f;
    background-color: rgb(195, 195, 195);
    color: #424242;
    font-size: 1rem;
    font-weight: 300;
    margin:5px 0 0 0 ;
    outline: none;
    padding: 20px 10px;
}
.sign-in-container a {
    font-size: 1rem;
    font-weight: 300;
    margin:25px 0 0 0 ;
    text-decoration: none;
    border-bottom: 2px solid #a3a3a300;
}
.sign-in-container a:hover {
    color: #b1b1ff;
    cursor: pointer;
    border-bottom: 2px solid #b1b1ff;
}
.sign-in-container button, .sign-up-container button {
    width: 60%;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid #8f8f8f;
    background-color: rgb(195, 195, 195);
    color: #323232;
    font-size: 1rem;
    font-weight: 300;
    margin:25px 0 0 0 ;
    outline: none;
}
.sign-in-container button:hover, .sign-up-container button:hover {
    cursor: pointer;
    background-color: rgb(42, 117, 255);
    border: 1px solid #4760ff;
}
.sign-in-container h4 {
    color: #2f2f2f;
    font-size: 1rem;
    font-weight: 500;
    margin:25px 0 0 0 ;
}
.sign-in-container h4:hover {
    cursor: pointer;
    color: #ff9909;
}
.sign-up-container {
    width: 50%;
    height:90%;
    background-color: rgb(243, 243, 243);
    position:absolute;
    right:10%;
    border-radius: 20px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    box-shadow: 5px 5px 30px 0px rgb(37, 37, 37);
}
.sign-up-side, .sign-in-side {
    width:100%;
    height:80%;
    border-radius: 20px;
    background-color: rgba(15, 59, 255, 0.302);
}

.sign-up-side section {
    width:45%;
    float:right;
    height:100%;
    padding: 0px 40px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}
.sign-in-side section {
    width:35%;
    float:left;
    height:100%;
    padding: 0px 40px;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sign-up-side h1, .sign-in-side h1 {
    color: #ebebeb;
    font-size: 1.6rem;
    font-weight: 500;
    margin:0px 0 0 0 ;
}

.sign-up-side h2, .sign-in-side h2 {
    color: #e6e6e6;
    font-size: 1rem;
    font-weight: 400;
    margin:0px 0 0 0 ;
}
.sign-in-side p {
    color: #ffa136;
    font-size: 1rem;
    font-weight: 400;
    margin:45px 0 0 0 ;
    border-bottom: 2px solid #ffa13600;
}
.sign-in-side p:hover {
    cursor: pointer;
    color: #ff9909;
    border-bottom: 2px solid #ff9909;
}

.sign-up-side button {
    width: 60%;
    height: 3rem;
    border-radius: 5px;
    border: 1px solid #8f8f8f;
    background-color: rgb(195, 195, 195);
    color: #323232;
    font-size: 1rem;
    font-weight: 300;
    margin:25px 0 0 0 ;
    outline: none;
}
.sign-up-side button:hover {
    cursor: pointer;
    background-color: rgb(42, 117, 255);
    border: 1px solid #4760ff;
}


.google-signin {
    width: 60%;
    min-width: 200px;
    display:flex;
    flex-direction: row;
    background-color: #dc4e41;
    align-items: center;
    justify-content: space-between;
    border-radius: 7px;
    margin-top: 25px;
}
.google-signin:hover {
    background-color: #f75f51;
    box-shadow: 1px 1px 5px 1px rgb(76, 76, 76);
    cursor: pointer;
}
.google-signin img {
    width: 40px;
    height: 40px;
    margin: 5px;
    border-radius: 6px;
}
.google-signin p{
    margin: 0 0px 0 0 ;
    width:100%;
    text-align: center;
    padding: 0 7px 0 0;
    color: #ffffff;
    font-size: .9rem;
    font-weight: 400;
}