.pricing-container {
    width:100%;
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}
.pricing-box {
    width:80%;
    height:70%;
    background-color: rgba(229, 229, 229, 0.408);
    z-index: 1000;
    border-radius: 15px;
    display:flex;
    flex-direction: row;
    align-items: center;
}
.border-right {
    border-right: 2px inset rgb(191, 191, 191);
}
.pricing-box div {
    width:50%;
    height: 90%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.pricing-box div span {
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    padding: 20px;
    border-bottom: 2px rgb(207, 207, 207) solid;
}
.pricing-box div span:last-child {
    border-bottom: none;
}
.align-left {
    align-items: flex-start !important;
    padding:0  5%;
}
.pricing-box div span .svg-inline--fa {
    color: #c7c7c7;
    height: 3rem;
}
.pricing-box h2 {
    color:#dedede;
    font-weight: 400;
    font-size: 1rem;
}
.pricing-box h1 {
    font-size: 5.5rem;
    font-weight: 600;
    color: rgb(255, 255, 255);
    margin:0;
}
.pricing-box p {
    font-size: 2rem;
    font-weight: 300;
    color: rgb(220, 220, 220);
    margin:0;
}
.pricing-box i {
    font-size: 1rem;
    font-weight: 300;
    color: rgb(179, 179, 179);
    margin:0;
}

.pricing-planet {
    position:absolute;
    left:0px;
    width: 220px;
    z-index: 900;
}
.pricing-left-title {
    position:absolute;
    font-size: 1.5rem;
    font-weight: 500;
    color:rgb(218, 218, 218);
    background-color: rgb(76, 76, 76);
    border: 2px #cccccc solid;
    padding: 5px 15px;
    border-radius: 20px;
    z-index: 1020;
    top:11%;
    left:23%
}
.pricing-right-title{
    position:absolute;
    top:11%;
    right:23%;
    font-size: 1.5rem;
    font-weight: 500;
    color:rgb(218, 218, 218);
    background-color: rgb(76, 76, 76);
    border: 2px #cccccc solid;
    padding: 5px 15px;
    border-radius: 20px;
    z-index: 1020;
}

.pricing-mobile {
    display:none;
    position:absolute;
    top:12%;
    font-size: 1rem;
    font-weight: 500;
    color:rgb(218, 218, 218);
    background-color: rgb(76, 76, 76);
    border: 2px #cccccc solid;
    padding: 5px 15px;
    border-radius: 20px;
    z-index: 1020;
}

@media only screen and (max-width: 820px) {
    .pricing-box {
        flex-direction: column;
        justify-content: space-evenly;
        
    }
    .pricing-left-title, .pricing-right-title {
        display:none;
    }
    .pricing-mobile {
        display:block;
    }
    .border-right {
        border-right: none;
        padding-top: 40px;
    }
    .pricing-box h1 {
        font-size: 3rem;
    }
    .pricing-box h2 {
        font-size: .8rem;
    }
    .pricing-box p {
        font-size: .9rem;
    }
    .pricing-box div {
        width:100%;
        height: auto !important;
    }
    .pricing-box div span:first-child {
        border-top: 1px rgb(207, 207, 207) solid;
        margin-top: 20px;
    }
    .pricing-box div span{
        border-bottom: 1px rgb(207, 207, 207) solid;
        padding: 10px;
    }
    .pricing-box div span .svg-inline--fa {
        height: 6vw;
    }
    .pricing-box i {
        font-size: .6rem;
        text-align: center;
    }
    .align-left {
        height: 100% !important ;
        width: 100% !important;
    }
    .pricing-planet {
        left: 260px;
        top: 90px;
    }
}