.sessions-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    
}
.sessions-container-header {
    display: flex;
    flex-direction: row;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    border-bottom-right-radius: 5px;

}
.sessions-container-header :last-child {
    border-bottom-right-radius: 5px;
}
.sessions-container-header button {
    border: none;
    padding: 10px 25px;
    font-size: .9rem;
    font-weight: 300;
    color: #565656;
    background-color: #302751;
    margin: 0;
}
.sessions-container-header button:hover {
    color:rgb(255, 255, 255);
    cursor: pointer;
}
.sessions-header-active {
    background-color: rgba(35, 61, 127, 0.673) !important;
    color:rgb(255, 255, 255) !important;
}
.sessions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 15px 20px 15px;
    width: 100%;
    height:85vh;
    overflow-y: scroll;
}
.sessions-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
}
.sessions h1 {
    text-align: center;
    font-size: 1.2rem;
}
.create-session-button {
    position:absolute;
    top:0;
    right: 0;
    padding: 10px 25px;
    background-color: transparent;
    border: 2px solid #757575;
    border-radius: 5px;
    color: #6c6c6c;
    margin: 10px;
    
}
.create-session-button:hover {
    cursor: pointer;    
    border: 2px solid #ffffff;
    color: rgb(255, 255, 255);
}
.create-session-container {
    position:absolute;
    top:0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.562);
    display: flex;

}
.create-session-exit {
    width:60%;
    height:100%;
}
.create-session-sidedrawer {
    border-top: 5px solid #5123f7;
    width:40%;
    height:100%;
    background-color: #5123f781;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
}