.header{
    width:100%;
    padding: 15px 30px 15px 30px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 100;
    position:relative;
}
.header h1 {
    height: 100%;
    border-bottom: 3px solid #ad6fe7;
    background: linear-gradient(93.51deg, #ad6fe7 2.84%, #3081ED 99.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
}
.header-tab-box {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    gap:40px;
    align-items: center;
}

.header img:hover {
    box-shadow: 3px 3px 3px 1px rgb(139, 139, 139);
}
.header-tab-box h3 {

    font-weight: 500;
    background-size: 100%;
    background-image: linear-gradient(93.51deg, #ffffff 2.84%, #b9cde7 99.18%);
    background-repeat: repeat;
    background-color: #CA4246;
    cursor: pointer;
    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; 
}
 
.header-tab-box h3:hover {
    background-image: linear-gradient(93.51deg, #ad6fe7 2.84%, #3081ED 99.18%);
    border-bottom: 2px solid #955ee1;

}
.header-tab-box button {
    padding: 15px 30px;
    border-radius: 10px;
    border: solid 2px #b7b7b7;
    background-color: transparent;
    color:#b7b7b7;
    font-weight: 600;
    font-size: 0.9rem;
    display:flex;
    justify-content: center;
    align-items: center;
}
.header-tab-box img {
    width: 20px ;
    height: 20px;
    margin-right: 10px;
    border-radius: 9px;
}
.header-tab-box button:hover {
    cursor: pointer;
    border: solid 2px #1c60e8;
    color:#fafafa;
    box-shadow: 0px 5px 6px 0px #1e65f4a9;
    transition: 0.2s;
}
.purple:hover {
    border: solid 2px #671ce8 !important;
    box-shadow: 0px 5px 6px 0px #a21ef4a9 !important;
}

@media only screen and (max-width: 454px)
{
    .header-tab-box {
        display:none}
    
    .header {
        justify-content:center;
    }
    .header h1 {
        font-size: 1rem;
    }
}