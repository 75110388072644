.card-popup-container {
    width:90%;
    height: 70%;
    background-color: rgba(58, 163, 255, 0.394);
    border-radius: 10px;
    z-index: 1010;
    animation: rocketup .5s;
    display:flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;

}
.card-popup-container div {

    background-color: rgba(0, 255, 255, 0);
    display:flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction: row;
}
.card-popup-container h1 {
    color: #d2d2d2;
    font-size: 1.8rem;
    font-weight: 500;
}
.card-popup-container p {
    color: #e4e4e4;
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #46464645;
    padding: 10px;
    border-radius: 10px;
}

.card-popup-container  .svg-inline--fa {
    color: #c9c9c9;
    height: 1.7rem;
    margin-right: 30px;
}
.card-popup-img-container {
    height: 100%;
}
.card-popup-container div img {
    height: 350px;
    border-radius: 40px;
    object-fit: cover;
    box-shadow: 5px 5px 25px 0px rgb(53, 53, 53);
}
.card-popup-container section {
    width: 50%;
    align-self: center;
}
.card-popup-wrapper {
    width:100%;
    height: 100%;
    backdrop-filter: blur(20px);
    z-index: 1000;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position:absolute
}
.card-popup-container-header {
    display:flex;
    justify-content: flex-start;
    gap: 10px;
    align-items: center;
    border: 1px solid #8f8f8f;;
    width: fit-content;
    padding: 5px 15px;
    border-radius: 10px;
}
.card-popup-container-header-box {
    width:90%;
    display:flex;
    margin-bottom: 10px;
    justify-content: flex-start;
}
.card-popup-container-header:hover .svg-inline--fa, .card-popup-container-header:hover p {
    color:#fafafa;
}
.card-popup-container-header:hover {
    cursor: pointer;
    border: 1px solid #ffffff;
}
.card-popup-container-header .svg-inline--fa {
    height: 30px;
    color: #b6b6b6;
}
.card-popup-container-header p {
    color: #b6b6b6;
    margin:0;
    font-size: 16px;
}