.titlebox_container {
    z-index: 20;
    position: relative;
    display:flex;
    flex-direction: row;
    width: 75%;
}

.titlebox_container h1, .titlebox_container span {
    color: #fafafa !important;
    font-weight: 600;
    font-size: 3.7em;
    letter-spacing: -3px;
    margin:0 12px 0 0;
    white-space: nowrap;
}
.wrapper {
    width: 70%;
    height: 100%;
    display: flex;
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
}
.left-content {
    width: 50%;
    height: 80%;
    top:15%;
    display: flex;
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
}

.right-content {
    width: 50%;
    top:15%;
    float: right;
    height: 80%;
    display: flex;
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
    left:0;
}
.rocket-wrapper {
    width: 50%;
    top:15%;
    float: right;
    height: 80%;
    display: flex;
    z-index: 10;
    position: relative;
    align-items: center;
    justify-content: center;
    left:10%;
}