.platform-background {
  background:  #2b2549;
    height:100vh;
    width:100vw;
    display:flex;
    flex-direction: column;
    margin: 0;
    --x: calc(var(--posX, 0) * 1px);
    --y: calc(var(--posY, 0) * 1px);
    background-image: 
      linear-gradient(115deg, rgb(211 255 215), rgb(0 0 0)), 
      radial-gradient( 90% 100% at calc( 50% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(77, 0, 165)), 
      radial-gradient(100% 100% at calc( 80% - var(--x)) calc( 0% - var(--y)), rgb(0, 0, 0), rgb(75, 128, 153)), 
      radial-gradient(150% 210% at calc(100% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(126, 108, 200)), 
      radial-gradient(100% 100% at calc(100% - var(--x)) calc(30% - var(--y)), rgb(0, 0, 0), rgb(120, 0, 184)), 
      linear-gradient(60deg, rgb(0, 17, 130), rgb(72, 0, 255));
    background-blend-mode: overlay, overlay, difference, difference, difference, normal;
    position: relative;
    z-index: 0;
    display:flex;
}


.page {
    display:flex;
    flex-direction: row;
    background-color: #f9f9f9;
}
