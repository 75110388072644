.invited-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.invited-container h1 {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0;
}
.invited-container p {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 400;
    padding: 0;
}
.invite-buttons button {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 600;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.5rem;
    margin : 1rem;
    cursor: pointer;
}
.invite-accept {
    background-color: #00bf236b;
}
.invite-accept:hover {
    background-color: #00bf23af;
}
.invite-decline {
    background-color: #ff000068;
}
.invite-decline:hover {
    background-color: #ff0000b4;
}