
.payment-container {
    display:flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.payment-container button {
    padding: 15px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-top: 50px;
    color: #ffffff;
    font-weight: 500;
    font-size: 1rem;
    background-color: rgba(63, 53, 255, 0.704);
}
.payment-container button:hover {
    cursor: pointer;
    background-color: rgba(63, 53, 255, 0.804);
    box-shadow: 5px 5px 30px 0px rgba(37, 37, 37, 0.204);
}
.payment-container-parent {
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
    
}
.payment-container-parent h1 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 500;
    color: rgb(230, 230, 230);
    padding: 0px 0px 25px 0px;
    width:100%;
}
.payment-container-parent p {
    font-size: 1rem;
    text-align: center;
    font-weight: 400;
    color: rgb(230, 230, 230);
    padding: 0px 10px;
    width:100%;
}
.payment-first {
    max-width: 400px;
    padding: 20px;

}

.payment-first button {
    padding: 15px 20px;
    border-radius: 5px;
    outline: none;
    border: none;
    margin-top: 50px;
    color: #ffffff;
    width: 100%;
    font-weight: 500;
    font-size: 1rem;
    background-color: rgba(255, 167, 53, 0.704);
}
.payment-first button:hover {
    cursor: pointer;
    background-color: rgba(231, 135, 9, 0.704);
    box-shadow: 5px 5px 30px 0px rgba(37, 37, 37, 0.204);
}

.student-payment-page {
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    height:100%;
    overflow: scroll;

}
.payment-container-row {
    display:flex;
    padding: 8px 0px;
    margin: 10px 30px;
    flex-direction: row;
    align-items: center;
}
.payment-middle {
    display:flex;
    width:100%;
    padding: 20px 30px;
    flex-direction: row;
    align-items: center;
    background-color: #8d8d8d;
}
.student-payment-page h1{
    padding: 0;
    margin: 50px 30px 10px 30px;
    color: #ffffff;
    font-size: 1.3rem;
    font-weight: 500;
}
.payment-summary {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-self: flex-start;
}
.payment-summary h1 {
    padding: 0;
}
.student-payment-page h2 {
    padding: 0;
    color: #8d8d8d;
    font-size: 1rem;
    width: 20%;
}
.card-details div {
    display:flex;
}
.card-details {
    width:80%;
}
.payment-container-row table, .card-details {
    
    background-color: #2c254c;
    box-shadow: 5px 5px 30px 0px rgba(37, 37, 37, 0.204);
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    padding: 15px 10px;
    box-shadow: 5px 5px 30px 0px rgba(37, 37, 37, 0.204);
}
.payment-container-row tbody {
    width: 100%;
}
.payment-container-row tr {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0px;
}
.faq {
    display:flex;
    padding: 8px 0px;
    margin: 20px 30px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    border-top: 2px solid #a9a9a9ac;
}
.faq h1 {
    padding: 0;
    margin: 0;
    color: #d3d3d3;
    font-size: 1.2rem;
    font-weight: 600;
}
.faq-question {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 15px 0px;
}
.faq-question h3 {
    padding: 0;
    margin: 0;
    color: #d3d3d3;
    font-size: .9rem;
    font-weight: 500;
}
.faq-question p {
    padding: 0;
    margin: 0;
    color: #9c9c9c;
    font-size: .8rem;
    font-weight: 400;
}
.payment-container-row th {
    color: #b1b1b1;
    font-size: 1.1rem;
    font-weight: 500;
    min-width: 200px;
    width:25%;
}
.payment-container-row td {
    color: #bcbcbc;
    font-size: 1rem;
    font-weight: 500;
    width:25%;
    min-width: 200px;
    text-align: center;
}

.card-details section {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.card-details .svg-inline--fa { 
    padding: 0 0px 0 10px;
    color: #8d8d8d;
}
.card-details h1 {
    padding: 10px 30px;
    text-transform: capitalize;
    color: #8d8d8d;
}
.card-details p{
    padding: 5px;
    color: rgba(255, 255, 255, 0.782);
    font-size: 1rem;
    margin: 0 10px 0 0 ;
}
.card-details span:hover {
    cursor: pointer;
    color: #e57200;
    border: 2px solid #e57200;
}
.card-details span:hover .svg-inline--fa{
    color: #e57200;
}
.card-details span{
    padding: 5px 10px;
    color: rgba(235, 235, 235, 0.538);
    font-size: .8rem;
    margin: 0 10px 0 0 ;
    border-radius: 7px;
    border: 2px solid rgb(154, 154, 154);
    white-space: nowrap;
}
.current-month-box h4 {
    padding:10px;
    color: #cccccc;
    border-radius: 6px;
    font-size: 1.4rem;
    border: 2px solid #cccccc;
}
.current-month-box h3 {
    color: #e4e4e4;
    font-size: 1.4rem;
    padding: 0;
}
.current-month-box h5 {
    color: #fafafa;
    padding: 0;
    font-weight: 400;
    margin:0;
    font-size: .8rem;
    width:100%;
    text-align: left;

}
.current-month-box {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0 10px;
}
.current-month-box div {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
}