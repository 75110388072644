
.rocket {
    position: relative;
    width: 80px;
    margin-bottom: 20%;
}
.animated-entrace {
    animation: rocketup 1s;
}
.rocket2{
    margin-bottom: 0% !important;
}
.rocket .rocket-body {
    width: 80px;
    left: calc(50% - 50px);
    animation: bounce 0.5s infinite;
}

.rocket .rocket-body .body-rocket {
    background-color: #dadada;
    height: 180px;
    left: calc(50% - 50px);
    border-top-right-radius: 100%;
    border-top-left-radius: 100%;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top: 5px solid #f5f5f5;
}
.rocket .rocket-body:before {
    content: '';
    position: absolute;
    left: calc(50% - 24px);
    width: 48px;
    height: 13px;
    background-color: #554842;
    bottom: -13px;
    border-bottom-right-radius: 60%;
    border-bottom-left-radius: 60%;
}
.rocket .window {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #a78348;
    left: calc(50% - 25px);
    top: 40px;
    border: 5px solid #b4b2b2;
}
.rocket .fin {
    position: absolute;
    z-index: -100;
    height: 55px;
    width: 50px;
    background-color: #a78348;
}
.rocket .fin-left {
    left: -30px;
    top: calc(100% - 55px);
    border-top-left-radius: 80%;
}
.rocket .fin-right {
    right: -30px;
    top: calc(100% - 55px);
    border-top-right-radius: 80%;
}
.rocket .exhaust-flame {
    position: absolute;
    top: 90%;
    width: 28px;
    background: linear-gradient(to bottom, transparent 10%, #ffffff7c 100%);
    height: 150px;
    left: calc(50% - 14px);
    animation: exhaust 0.2s infinite;
}
.rocket .exhaust-fumes li {
    width: 30px;
    height: 30px;
    background-color: #fff2da;
    list-style: none;
    position: absolute;
    border-radius: 100%;
}
.rocket .exhaust-fumes li:first-child {
    width: 100px;
    height: 100px;
    bottom: -300px;
    animation: fumes 5s infinite;
}
.rocket .exhaust-fumes li:nth-child(2) {
    width: 50px;
    height: 50px;
    left: -120px;
    top: 260px;
    animation: fumes 3.2s infinite;
}
.rocket .exhaust-fumes li:nth-child(3) {
    width: 100px;
    height: 100px;
    left: -40px;
    top: 330px;
    animation: fumes 3s 1s infinite;
}
.rocket .exhaust-fumes li:nth-child(4) {
    width: 100px;
    height: 100px;
    left: -170px;
    animation: fumes 4s 2s infinite;
    top: 380px;
}
.rocket .exhaust-fumes li:nth-child(5) {
    width: 130px;
    height: 130px;
    left: -120px;
    top: 350px;
    animation: fumes 5s infinite;
}
.rocket .exhaust-fumes li:nth-child(6) {
    width: 200px;
    height: 200px;
    left: -60px;
    top: 280px;
    animation: fumes2 10s infinite;
}
.rocket .exhaust-fumes li:nth-child(7) {
    width: 100px;
    height: 100px;
    left: -100px;
    top: 320px;
}
.rocket .exhaust-fumes li:nth-child(8) {
    width: 110px;
    height: 110px;
    left: 70px;
    top: 340px;
}
.rocket .exhaust-fumes li:nth-child(9) {
    width: 90px;
    height: 90px;
    left: 200px;
    top: 380px;
    animation: fumes 20s infinite;
}
.star-rocket li {
    list-style: none;
    position: absolute;
}
.star-rocket li:before, .star-rocket li:after {
    content: '';
    position: absolute;
    background-color: #ffffff;
}
.star-rocket li:before {
    width: 10px;
    height: 2px;
    border-radius: 50%;
}
.star-rocket li:after {
    height: 8px;
    width: 2px;
    left: 4px;
    top: -3px;
}
.star-rocket li:first-child {
    top: -30px;
    left: -210px;
    animation: twinkle 1.4s infinite;
}
.star-rocket li:nth-child(2) {
    top: 0;
    left: 60px;
    animation: twinkle 1.5s infinite;
}
.star-rocket li:nth-child(2):before {
    height: 1px;
    width: 5px;
}
.star-rocket li:nth-child(2):after {
    width: 1px;
    height: 5px;
    top: -2px;
    left: 2px;
}
.star-rocket li:nth-child(3) {
    left: 120px;
    top: 220px;
    animation: twinkle 1s infinite;
}
.star-rocket li:nth-child(4) {
    left: -100px;
    top: 200px;
    animation: twinkle 1.5s ease infinite;
}
.star-rocket li:nth-child(5) {
    left: 170px;
    top: 100px;
    animation: twinkle 1.4s ease infinite;
}
.star-rocket li:nth-child(6) {
    top: 87px;
    left: -79px;
    animation: twinkle 1s infinite;
}
.star-rocket li:nth-child(6):before {
    height: 1px;
    width: 5px;
}
.star-rocket li:nth-child(6):after {
    width: 1px;
    height: 5px;
    top: -2px;
    left: 2px;
}
@keyframes fumes {
    50% {
        transform: scale(1.5);
        background-color: transparent;
   }
    51% {
        transform: scale(0.8);
   }
    100% {
        background-color: #ffffff99;
        transform: scale(1);
   }
}
@keyframes bounce {
    0% {
        transform: translate3d(0px, 0px, 0);
   }
    50% {
        transform: translate3d(0px, -4px, 0);
   }
    100% {
        transform: translate3d(0px, 0px, 0);
   }
}
@keyframes exhaust {
    0% {
        background: linear-gradient(to bottom, transparent 10%, #f5f5f57d 100%);
   }
    50% {
        background: linear-gradient(to bottom, transparent 8%, #f5f5f583 100%);
   }
    75% {
        background: linear-gradient(to bottom, transparent 12%, #f5f5f586 100%);
   }
}
@keyframes fumes2 {
    50% {
        transform: scale(1.1);
   }
}
@keyframes twinkle {
    80% {
        transform: scale(1.5);
        opacity: 0.2;
   }
}

@keyframes rocketup {
    0% {
        margin-top:100%;
    }
    100%{
        margin-top:0%;
    }
}


@media only screen and (max-width: 804px) {
    .rocket-wrapper {
        width: 100%;
        top:55%;
        float:none;
        left:auto;
    }
}