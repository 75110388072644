.schedule-side-drawer-container {
        width:35%;
        min-width: 350px;
        height:100vh;
        position:absolute;
        top:0;
        overflow: scroll;
        right:0;
        background-color: #352c56;
        box-shadow: 0 0 1px rgba(0,0,0,.1),0 5px 24px rgba(0, 0, 0, 0.3);
        border-top: 5px solid #ff8438;
        display:flex;
        flex-direction: column;
        align-items: center;
        padding: 0 20px 0 20px;
        animation: 0.2s scheduleside ease-in;
        z-index: 1000;
}
.react-time-picker {
    position:relative;
    width:100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;

}
.react-time-picker__wrapper {
    width:100%;
    height:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:20px;
    border:none !important;

}
.react-time-picker__clock {
    width: 100% !important;
    border:none !important;
    background-color: #ffffff !important;
    margin-top: 20px;
    border-radius: 20px !important;
    display: flex;
    justify-content: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.7);

}

.schedule-side-drawer-container h4 {
    color: rgb(227, 227, 227);
    font-size: 1.7rem;
    font-weight: 500;
    text-align: center;
    width:100%;
    padding: 0 0 10px 0;
    border-bottom: 3px solid rgba(227, 227, 227, 0.477);
    margin: 20px 0 0px 0;
}
.schedule-side-drawer-container h3 {
    color: rgb(164, 164, 164);
    font-size: .8rem;
    font-weight: 300;
    width:100%;
    text-align: start;
    margin: 30px 0 0 0;
    padding:0;
}
.schedule-side-drawer-container textarea {
    width:100%;
    height:150px;
    border: 2px solid rgba(220, 220, 220, 0.277);
    border-radius: 10px;
    background-color: transparent;
    padding: 10px;
    font-size: 1rem;
    font-weight: 300;
    color:rgb(147, 147, 147);
    margin: 10px 0 10px 0;
    resize: none;
    outline: none;
}
.react-time-picker input, .react-time-picker span, .react-time-picker select {
    color:rgb(190, 117, 0) !important
}
.calendar-event-button {
    height:50px;
    border: 2px solid  rgb(167, 167, 167);
    border-radius: 10px;
    padding: 10px 20px;
    align-self: flex-end;
    font-size: .8rem;
    font-weight: 300;
    margin: 10px 0 10px 0;
    background-color: #d0d0d044;
    color:  rgb(167, 167, 167);
    font-weight: 600;
    font-size: 1.2rem;
    cursor: pointer;
}
.calendar-event-button:hover {
    border: 2px solid  rgb(255, 255, 255);
    color:  rgb(255, 255, 255);
}
.schedule-side-drawer-container p {
    color: rgb(142, 142, 142);
    font-size: .9rem;
    font-weight: 400;
    width:100%;
    text-align: start;
    padding: 20px 0px;
    margin: 10px 0 10px 0;
    line-height: 1.5rem;
    border-bottom: 1px solid rgba(228, 228, 228, 0.071);
}
.schedule-side-drawer-container h5 {
    color: rgb(165, 165, 165);
    font-size: 1.2rem;
    margin: 0px 0 0 0;
    font-weight: 600;
    width:100%;
    text-align: center;
}
.edit-row {
    width:100%;
    gap:20px;
    margin: 20px 0 0 0;
    border-radius: 10px;
}
.edit-time {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.edit-row  .svg-inline--fa{
    color: rgb(205, 205, 205);
    height: 1.2rem;
    padding : 4px;
    border-radius: 5px;
}
.edit-row  .svg-inline--fa:hover{
    cursor: pointer;
    background-color: rgb(205, 205, 205);
    color: #FFF;
}
.react-time-picker input:hover, .react-time-picker select:hover{
    cursor:pointer;
}
.react-time-picker input, .react-time-picker select, .react-time-picker span{
    font-size: 1.2rem;
    font-weight: 600;
    color:rgb(147, 147, 147)
}

@keyframes timebox {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.update-request { 
    border-radius: 15px;
    padding: 10px 20px;
    background-color: #9bb0e4;
    color: #FFF;
    font-size: 1rem;
    border:none;
    outline: none;
    margin-top: 20px;
}
.update-request:hover {
    cursor: pointer;
    background-color: #7d8de8;
}
.schedule-side-drawer-wrapper {
    width:100%;
    height:100%;
    display:flex;
    position:absolute;
    flex-direction: row;
    z-index: 1000;
}
.schedule-side-drawer-header button {
    border:none;
    background-color: transparent;
    outline: none;
    color: rgba(222, 45, 14, 0.766);
    padding: 10px 15px;
    margin-top:5px;
    border-radius: 10px;
    font-weight: 600;
    background-color: rgb(248, 248, 248);
}
.schedule-side-drawer-header button:hover {
    cursor: pointer;
    color: #fafafa;
    background-color: rgba(222, 45, 14, 1);
}
.deletebox {
    width:100%;
    height:100%;
    display:flex;
    position:absolute;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadeIn 0.5s;
}
.deletebox h3 {
    text-align: center;
}
.deletebox div {
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px 0 150px 0;
}
.deletebox button {
    border:none;
    background-color: transparent;
    outline: none;
    color: rgb(255, 255, 255);
    padding: 15px 35px;
    border-radius: 5px;
    font-weight: 600;
}
.deletebox .yes {
    background-color: rgba(12, 187, 32, 0.739);
}
.deletebox .yes:hover {
    cursor: pointer;
    background-color: rgb(0, 179, 0);
}
.deletebox .no {
    background-color: rgba(222, 45, 14, 0.69);
}
.deletebox .no:hover {
    cursor: pointer;
    background-color: rgb(255, 0, 0);
}
.schedule-side-drawer-exit-wrapper {
    width:65%;
    height:100%;
}

.schedule-side-drawer-header {
    width:100%;
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
}
.schedule-side-drawer-header .svg-inline--fa{
    color: rgb(205, 205, 205);
    height: 1.2rem;
    border-radius: 5px;
    padding-top: 10px;
}
.schedule-side-drawer-header .svg-inline--fa:hover {
    cursor:pointer;
    color: rgb(73, 73, 73);
}
@keyframes scheduleside { 
    from {
        margin-right: -600px;
        opacity: 0;
      }
      to {
        margin-right:0;
        opacity: 1;
      }
    }

.length-box {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
    margin: 10px 0 0px 0;
}
.length-box section {
    display:flex;
    flex-direction: column;
    width: 80px;
}
.length-box label {
    color:rgb(142, 142, 142);
    font-size: .7rem;

}
.length-box select {
    width: 100%;
    height: 40px;
    border: 2px solid rgba(220, 220, 220, 0.362);
    border-radius: 10px;
    background-color: transparent;
    padding: 3px;
    font-size: 1rem;
    font-weight: 300;
    color:rgb(147, 147, 147);
    margin: 10px 0 10px 0;
    outline: none;
}
.length-box input:focus,  .schedule-side-drawer-container textarea:focus{
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.321);
}

.fc-daygrid-event-harness:hover .student-request{
    background-color: rgb(201, 104, 0) !important;
}
.student-request {
    overflow: hidden;
    background-color: rgb(255, 132, 0) !important;
    color: #FFF;
}
.fc-daygrid-event-harness:hover .tutor-request{
    background-color: rgb(92, 0, 184) !important;
}
.tutor-request{
    background-color: rgb(128, 0, 255) !important;
    color: #FFF;
}
.fc-daygrid-event-harness:hover .request-confirmed{
    background-color: rgb(0, 138, 23) !important;
}
.request-confirmed {
    background-color: rgb(0, 190, 32) !important; 
    color: #FFF;
}
.session-attended {
    background-color: rgb(97, 0, 167) !important;
    color: #FFF;
}
.date-picker,.student-select {
    display: flex;
    align-self: flex-start;
    border-radius: 10px;
    border: 2px solid rgba(220, 220, 220, 0.259);
    background-color: transparent;
    color:  rgb(214, 214, 214);
    margin: 10px 0 0px 0;
    padding: 10px 20px;
}
