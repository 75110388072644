

:root {
    --card-color: rgba(208, 208, 208, 0.078);
  }
  
  
  #cards {
    z-index: 100;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;  
    justify-content: center;
    position: relative;
  }
  
  #cards:hover > .card::after {
    opacity: 1;
  }
  
  .card {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    height: 360px;
    flex-direction: column;
    position: relative;
    width: 360px;  
  }
  
  .card:hover::before {
    opacity: 1;
    background-color: rgba(209, 209, 209, 0.499);
    box-shadow: 1px 1px 5px 1px rgb(41, 41, 41);
  }
  .card:hover .card-info-title > h3{
    color:#fafafa;
  }
  .card::before,
  .card::after {
    border-radius: inherit;
    content: "";
    height: 100%;
    left: 0px;
    opacity: 0;
    position: absolute;
    top: 0px;
    transition: opacity 500ms;
    width: 100%;
  }
  
  .card::before {
    background: radial-gradient(
      800px circle at var(--mouse-x) var(--mouse-y), 
      rgba(255, 255, 255, 0.06),
      transparent 40%
    );
    z-index: 3;
  }
  
  .card::after {  
    background: radial-gradient(
      600px circle at var(--mouse-x) var(--mouse-y), 
      rgba(255, 255, 255, 0.695),
      transparent 40%
    );
    z-index: 1;
  }
  
  .card > .card-content {
    background-color: var(--card-color);
    border-radius: inherit;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    inset: 1px;
    padding: 0px;
    position: absolute;
    z-index: 2;
  }
  
  
  .card-image {
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .card-image > i {
    font-size: 6em;
    opacity: 0.25;
  }
  
  .card-info-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 0px 20px;
    margin-top: 20px;
  }
  
  .card-info {
    align-items: flex-start;
    display: flex;
    gap: 10px;
  }
  
  .card-info > i {  
    font-size: 1em;
    height: 20px;
    line-height: 20px;
  }
  
  .card-info-title > h3 {
    font-size: 1.1em;
    line-height: 20px;
    color: #9a9a9a;
  }
  
  .card-info-title > h4 {
    color: rgba(196, 196, 196, 0.5);
    font-size: 0.85em;
    margin-top: 8px;
  }

.card-image .svg-inline--fa{
    height: 3em !important;
    color:rgb(172, 172, 172)
}

@media screen and (max-width: 2050px){
    .card {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        height: 220px;
        flex-direction: column;
        position: relative;
        width: 220px;  
      }
}

@media screen and (max-width: 1508px){
    .card {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        height: 180px;
        flex-direction: column;
        position: relative;
        width: 180px;  
      }
      .card-image > i {
        font-size: 6em;
        opacity: 0.25;
      }
      
      .card-info-title > h3 {
        font-size: .8em;
      }
      
      .card-info-title > h4 {
        font-size: 0.65em;
        margin-top: 8px;
      }
}


@media screen and (max-width: 1235px){
    .card {
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 10px;
        cursor: pointer;
        display: flex;
        height: 140px;
        flex-direction: column;
        position: relative;
        width: 140px;  
      }
      .card-image .svg-inline--fa{
        height: 30px !important;
      }
      
      .card-info-title > h3 {
        font-size: .8em;
        line-height: 20px;
      }
      
      .card-info-title > h4 {
        font-size: 0.65em;
        margin-top: 8px;
      }
}

@media only screen and (max-width: 454px) {
  .left-content {
    display: none;
  }
  .right-content {
    width: 100%;
  }
  .container-blind h1 {
    font-size: 1.5em;
    text-align: center;
  }
  .container-blind p {
    font-size: 1em;
    text-align: center;
  }
}