.side-menu-container {
    width: 12%;
    min-width: 220px;
    background-color: #272042;
    height: 100vh;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.102);
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 1000;

}


@keyframes slidein {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

.side-menu-container h1 {
    margin:50px 0px 40px 0px;
    border-bottom: 3px solid #ffffff;
    background: linear-gradient(93.51deg, #ead4ff 2.84%, #cce2ff 99.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
}
.tab {
    width:100%;
    height: 50px;
    border:none;
    background-color: transparent;
    color: #454545;
    display:flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}
.tab span {
    font-size: .9rem;
    font-weight: 500;
    color: #8d8d8d;
}
.tab .svg-inline--fa {
    margin: 0 20px;
}
.tab:hover span {
    color: #ffffff;
}
.tab:hover .invis{
    opacity: 1 !important;
    
}
.invis {
    opacity: 0;
    height: 1.4rem;
    margin-right: 30px;
    color: #ffffff;
}
.side-menu-container .active {
    background-color: #f9f9f9;
    box-shadow: 4px 4px 10px #d1d9e6, -4px -4px 10px #f9f9f9;
}
