

.container-rocket3{
    height: 200px;
    width: 200px;
    position: relative;
    margin:20px 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.moon-rocket3{
    background-color: #39beff;
    height: 120px;
    width: 120px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
}

.crater{
    background-color: #31b4ff;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    position: relative;
}

.crater:before{
    content: "";
    position: absolute;
    height: 25px;
    width: 25px;
    border-radius: 50%;
    box-shadow: -5px 0 0 2px #1ca4f9;
    top: 2px;
    left: 7px;
}

.crater-1{
    top: 27px;
    left: 90px;
    transform: scale(0.9);
}

.crater-2{
    bottom: 15px;
    left: 61px;
    transform: scale(0.6);
}

.crater-3{
    left: 15px;
    transform: scale(0.75);
}

.crater-4{
    left: 107px;
    top: 32px;
    transform: scale(1.18);
}

.crater-5{
    left: 33px;
    bottom: 4px;
    transform: scale(0.65);
}

.shadow-rocket3{
    height: 190px;
    width: 190px;
    box-shadow: 21px 0 0 5px rgba(0,0,0,0.15);
    border-radius: 50%;
    position: relative;
    bottom: 157.5px;
    right: 46px;
}

.eye-rocket3{
    height: 12px;
    width: 12px;
    background-color: #161616;
    position: relative;
    border-radius: 50%;
}

.eye-l{
    bottom: 285px;
    left: 29px;
}

.eye-r{
    bottom: 297px;
    left: 81px;
}

.mouth-rocket3{
    height: 5px;
    width: 10px;
    border: 3px solid #161616;
    position: relative;
    bottom: 292px;
    left: 55px;
    border-top: none;
    border-radius: 0 0 10px 10px;
}

.blush{
    height: 7.5px;
    width: 7.5px;
    background-color: #1ca4f9;
    position: relative;
    border-radius: 50%;
}

.blush-1{
    bottom: 273px;
    left: 50px;
}

.blush-2{
    bottom: 281px;
    left: 115px;
}

.orbit-rocket3{
    height: 200px;
    width: 200px;
    border-radius: 50%;
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    animation: spin 5s infinite linear;
}

@keyframes spin{
    100%{
        transform: rotate(360deg);
    }
}

.rocket-rocket3{
    background-color: #fafcf7;
    height: 50px;
    width: 25px;
    border-radius: 50% 50% 0 0;
    position: relative;
    left: -11px;
    top: 115px;
}

.rocket-rocket3:before{
    position: absolute;
    content: "";
    background-color: #39beff;
    height: 20px;
    width: 55px;
    z-index: -1;
    border-radius: 50% 50% 0 0;
    right: -15px;
    bottom: 0;
}

.rocket-rocket3:after{
    position: absolute;
    content: "";
    background-color: #39beff;
    height: 4px;
    width: 15px;
    border-radius: 0 0 2px 2px;
    bottom: -4px;
    left: 4.3px;
}

.window-rocket3{
    height: 10px;
    width: 10px;
    background-color: #151845;
    border: 2px solid #b8d2ec;
    border-radius: 50%;
    position: relative;
    top: 17px;
    left: 5px;
}