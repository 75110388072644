.loader-container {
    margin-top:20%;
    width:80px;
    height:80px;
    display:grid;
    grid-template-columns:repeat(3, 1fr);
    gap:0.3rem;
    transform:rotate(-45deg);
  }
  
  .square {
    background-color:rgb(255, 255, 255);
    display:grid;
    place-items:center;
    border-radius:5px;
    animation:load 1.6s ease infinite;
  }
  
  @keyframes load {
    0% {
      transform:scale(1);
    }
    50% {
      transform:scale(0);
      background-color:var(--color);
    }
    100% {
      transform:scale(1);
    }
  }
  
  .one {
    --color:rgb(106, 0, 219);
  }
  
  .two {
    animation-delay:0.1s;
    --color:rgb(0, 106, 226);
  }
  
  
  .three {
    animation-delay:0.2s;
    --color:rgb(243, 150, 0);
  }
  
  
  .four {
    animation-delay:0.3s;
    --color:rgb(0, 106, 226);
  }
  
  
  .five {
    animation-delay:0.4s;
    --color:rgb(106, 0, 219);
  }