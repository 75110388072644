.container{
    width: 95%;
    height: 100%;
    background-color: rgba(250, 235, 215, 0.071);
    z-index: 3;
    border-radius: 15px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container-blind {
    width: 95%;
    height: 100%;
    background-color: rgba(250, 235, 215, 0);
    z-index: 3;
    border-radius: 15px;
    display:flex;
    gap:45px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.full {
    width: 100%;
}
.container h1, .container-blind h1 {
    color: rgb(208, 208, 208);
    font-weight: 300;
    font-size: 2.2rem;
}
.tutor-container h1 {
    color: rgb(208, 208, 208);
    font-weight: 500;
    margin: 25px 0;
    font-size: 2.7rem;
}

.container p, .container-blind p, .tutor-container p  {
    color: rgb(159, 159, 159);
    font-weight: 300;
    font-size: 1.2rem;
    width:100%;
    line-height: 1.7rem;
}


@media only screen and (max-width: 454px) {
    .container {
        background-color: #a4a4a447;
        width:90%;
        height: 100%;
    }
}
