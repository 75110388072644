/*inheret from platform-right-container*/

.fc {
    width: 100% !important;
    height: 85% !important;
}

.fc .fc-daygrid-day.fc-day-today{
    background-color: #463c858a !important;
    color: #f5f5f5;
}
.fc-daygrid-day:hover {
    cursor: pointer;
    background-color: #553d9b9e !important;

}
.fc-h-event .fc-event-main {
    text-overflow: ellipsis;
    overflow: hidden;
}
.fc .fc-toolbar-title {
    color: #f5f5f5;
}
.fc a{
    color: #d5d5d5;
}
.fc .fc-button-primary {
    background-color: transparent !important;
    border-color: #d9d9d9 !important;
    color: #dfdfdf;
}
.fc-theme-standard .fc-scrollgrid {
    border: none !important;
}
.fc-theme-standard th {
    border: none !important;
}
.fc-theme-standard td{
    border: 1px solid #2d2d2d !important;
}
.fc-theme-standard th {
    border: none;
}
.firstConsult-box {
    width:100%;
    background-color: rgba(255, 255, 255, 0.1);
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap:15px;
}
.firstConsult-box h4 {
    margin:0;
    padding:10px 0;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(213, 213, 213);
}
.firstConsult-box .svg-inline--fa  {
    color:#bcbcbc;
    height: 1.5rem;
}
.loader-center {
    height: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
}