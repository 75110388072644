/*inheret from platform-right-container*/

.student-profile-events-container {
    width:100%;
    border-top: 2px solid rgb(227, 227, 227)
}
.student-profile-top{
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 25px 0;
}
.student-profile-scroll-wrapper{
    overflow: scroll;
    width: 100%;
    height: 100%;
}
.student-profile-container {
    width:100%;
    height: 100vh;
    display:flex;
    animation: in .5s;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.student-profile-events-container h1 {
    font-weight: 300;
    font-size: 1.2rem;
    color: rgb(126, 126, 126);
    margin: 20px 20px 20px 30px;
    border-bottom: 1px solid rgb(227, 227, 227);
}
.student-profile-container section {

    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    border-radius: 20px;
    border: 2px solid rgb(227, 227, 227);
}
.profile2 {
    width:60%;
    box-shadow: none !important;
}
.student-profile-container section img {
    border-radius: 40px;
    height: 70px;
}
.student-profile-container section h3 {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 20px;
    color: rgb(126, 126, 126);
}
.student-profile-container section p {
    font-size: .9rem;
    font-weight: 300;
    margin: 5px 0 0 0;
    color: rgb(126, 126, 126);
}
.profile-row {
    background-color: azure;
}

.student-profile-event {
    width:100%;
    display:flex;
    flex-direction: column;
    animation: in .5s;
    border-bottom: 1px solid rgba(201, 201, 201, 0.144);
}
.student-profile-event:last-child {
    border-bottom: none;
}
.student-profile-event:last-child:hover {
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
}
.student-profile-event:first-child:hover {
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
}
.student-profile-event h1 {
    font-size: 1.2rem;
    font-weight: 600;
    color: rgb(83, 83, 83);
    border-bottom: none;;
    margin: 0;
    padding: 0;
    text-align: center;
}
.student-profile-event h2 {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    text-align: center;
    padding: 0;
    white-space: nowrap;
    color: rgb(255, 255, 255);
}
.student-profile-event h3 {
    font-size: .7rem;
    font-weight: 300;
    margin: 0;
    padding: 0;
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 0;
}
.student-profile-event h4 {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
    text-align: center;
    color: rgb(210, 210, 210);
}
.student-profile-event h5 {
    font-size: 1rem;
    font-weight: 500;
    text-align: start;
    margin: 0;
    padding: 0;
    color: rgb(150, 150, 150);
    margin: 0;
}
