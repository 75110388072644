.container-joinus {
    width: 95%;
    height: 100%;
    background-color: rgba(250, 235, 215, 0.071);
    z-index: 3;
    border-radius: 15px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 30px;
}
.container-joinus img {
    width: 50%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 2px 2px 15px 0px rgb(175, 175, 175);

}
.container-joinus div {
    display:flex;
    flex-direction: column;
}
.container-joinus h1 {
    color: rgb(220, 220, 220);
    font-weight: 300;
    font-size: 1.4rem;
    margin: 0 0px 20px 30px;
}
.container-joinus h2 {
    color: rgb(171, 171, 171);
    font-weight: 300;
    font-size: 1rem;
    margin: 0 0 0 30px;
}
.portal-button {
    border-radius: 5px;
    outline: none;
    border: 2px solid #c1c1c1;
    padding: 20px;
    background-color: #ffffff63;
    font-weight: 400;
    color:#fafafa;
    font-size: 1.2rem;
    margin: 40px 0 0 30px;
    align-self: flex-start;
    display:flex;
    gap: 10px;
}
.portal-button:hover {
    border: #fafafa 2px solid;
    background-color: #0080ff63;
    cursor: pointer;
    box-shadow: 2px 2px 15px 0px rgb(175, 175, 175);

}

@media only screen and (max-width: 654px) {
    .container-joinus{
        flex-direction: column;
        padding: 0px 0px;
    }
    .container-joinus div {
        padding: 25px 10px;
    }
    .container-joinus img {
        width: inherit;
    }
    .container-joinus h1 {
        font-size: 1.2rem;
        margin: 0 0px 20px 0px;
        text-align: left;
    }
    .container-joinus h2 {
        font-size: 0.8rem;
        margin: 0 0 0 0px;
    }
    .portal-button {
        margin: 40px 0 0 0px;
        align-self: center;
        font-size: 1rem;
    }
}