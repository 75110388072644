.platform-header-container {
    width:100%;
    background-color: #302751;
    border-bottom: 1px solid #dddddd0d;
    box-shadow: 0 0px 15px 5px rgba(0, 0, 0, 0.120);
    min-height:70px;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.platform-header-container h1 {
    font-size: 1.2rem;
    font-weight: 400;
    color: #ffffff;
    margin:0;
    padding:0;
}

.platform-header-container .svg-inline--fa {
    color: #ffffff;
    height: 1.5rem;
    margin: 0px 15px 0px 25px;
}
.icon-header-box .svg-inline--fa {
    color: #c8c8c8;
    height: 1.5rem;
    margin: 0px 15px 0px 25px;
}

.icon-header-box:hover span {
    background-color: rgb(218, 105, 0);
}
.tab-header-box{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.icon-header-box{
    margin: 0 25px 0 0;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: relative;
}

.icon-header-box:hover  .svg-inline--fa{
    color: #ffffff;
    cursor: pointer;
} 

.icon-badge-bell{
    left:40px;
}
.icon-badge-bell {
    color: #ffffff;
    font-size: 0.7rem;
    font-weight: 400;
    border-radius: 50%;
    position:absolute;
    width: 25px;
    top:-10px;
    height: 20px;
    background-color: rgb(176, 85, 0);
    display:flex;
    justify-content: center;
    align-items: center;

}
