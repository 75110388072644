.notification-container{
    position:absolute;
    background-color: rgb(255, 159, 86);
    border-radius: 10px;
    top: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
    animation: notificationIn 3s forwards;
    box-shadow: 0px 0px 5px 0px rgba(58, 58, 58, 0.823);
}
.notification-container .svg-inline--fa {
    color: #ffffff;
    height: 1.2rem;
    margin-right: 30px;
}
.notification-container h1 {
    color: #ffffff;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 20px;

}

@keyframes notificationIn {
    0% {
        transform: translateY(-100%);
        opacity: .3;
    }
    15% {
        transform: translateY(0);
        opacity: 1;
    }
    85% {
        transform: translateY(0);
        opacity: 1;
    }
    100% {
        transform: translateY(-100%);
        opacity: 0;
    }
}
    