.event-confirmed{
    color: rgb(0, 167, 31) !important;
}
.event-pending{
    color: rgb(243, 122, 0) !important;
}
.event-updated {
    color: rgb(0, 81, 167) !important;
}
.event-attended {
    color: rgb(97, 0, 167) !important;
}
.student-profile-event-date span {
    font-size: .5rem;
    font-weight: 500;
    text-align: center;
    margin: 5px 0 0 0 !important;
    border-radius: 5px;
    padding: 3px 5px!important;
    background-color: rgb(255, 255, 255);
}

.student-profile-event p {
    font-size: .7rem;
    font-weight: 300;
    text-align: start;
    margin: 0;
    padding: 0;
    color: rgb(200, 200, 200);
    margin: 0;
}

.student-profile-event-date {
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 20px;
    margin: 5px 10px;
    padding: 5px 15px;
}
.student-profile-event-info {
    width:50%;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0px 20px 0px;

}
.student-profile-event-info div {
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 15px;
}
.session-event-buttons {
    width: 30%;
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0px 10px 0 0 ;

}

.session-event-buttons button {
    border: none;
    padding: 6px 10px;
    font-size: 1.3rem;
    font-weight: 300;
    display:flex;
    gap:15px;
    white-space:nowrap;
    color: rgb(175, 175, 175);
    position:relative;
    border-radius: 5px;
    background-color: transparent;
}
.session-event-buttons button span {
    font-size: .8rem;
    font-weight: 300;
    top: -25px;
    padding: 3px 5px;
    border-radius: 3px;
    left: -17px;
    background-color: rgba(174, 174, 174, 0.586);
    text-align: center;
    color: rgb(224, 224, 224);
    position:absolute;
    display:none;
}
.session-event-buttons button:hover span {
    display:block;
}
.session-event-buttons button:hover {
    cursor: pointer;
}
.zoom-button {
    color:rgba(152, 152, 255, 0.632) !important;
}
.zoom-confirm {
    color:rgba(0, 179, 0, 0.632) !important;
}
.zoom-confirm:hover {
    background-color:rgb(0, 179, 0) !important;
    color: white !important;
}
.zoom-button:hover {
    background-color:rgb(91, 91, 255) !important;
    color: white !important;
}
.zoom-resched {
    color:rgba(255, 170, 59, 0.692) !important;
}
.zoom-resched:hover {
    background-color:rgb(255, 158, 31) !important;
    color: white !important;
}
.zoom-remove{
    color:rgba(255, 59, 59, 0.483) !important;
}
.zoom-remove:hover {
    background-color:rgba(255, 59, 59, 0.693) !important;
    color: white !important;
}

.mark-attended, .assign-session {
    color:rgba(146, 0, 179, 0.632) !important;
}

.mark-attended:hover, .assign-session:hover {
    background-color:rgb(146, 0, 179) !important;
    color: white !important;
}
.assign-box {
    padding: 5px 30px;
    border-radius: 15px;
    display:flex;
    flex-direction: row;
    align-items: center;
    background-color: rgba(98, 121, 175, 0.645);
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.55);
}
.assign-box .svg-inline--fa{
    color: white;
    font-size: .8rem;
    background-color: rgba(0, 142, 0, 0.879);
    padding: 10px;
    margin: 0 0px 0 15px;
    border-radius: 5px;
    
}
.assign-box .svg-inline--fa:hover {
    cursor: pointer;
    background-color: rgb(0, 167, 0);
}
.assign-box section {
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5px 0;
}
.assign-box select {
    outline: none;
    border: none;
    background-color: transparent;
    color: white;
    font-size: .8rem;
    font-weight: 500;
    padding: 10px;
}
.pending{
    background-color: rgba(253, 148, 0, 0.805);
}

.updated {
    background-color: rgb(64, 148, 208);

}
.missed {
    background-color: rgba(207, 0, 0, 0.697);
}
.confirmed {
    background-color: rgb(71, 187, 80);
}
.attended {
    background-color: rgb(97, 0, 167);

}
.cancel-confirm {
    display:flex;
}
.cancel-confirm button {
    color: white !important;
    padding: 5px 15px !important;
    align-items: center !important;
    border:none;
    font-size: .8rem;
}

.cancel-confirm-yes {
    background-color: rgba(71, 187, 81, 0.643) !important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cancel-confirm-no {
    background-color: rgba(213, 0, 0, 0.537) !important;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}
.mark-attended-box {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width:100%;
    height: 220px; 
    border-radius: 7px;
    background-color: transparent;
    animation: attended-box .3s ease-in-out;
}
.mark-attended-box-buttons{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 10%;
    gap: 10px;
}
.mark-attended-box-buttons button {
    width: 70%;
    padding: 15px 5px;
    border-radius: 5px;
    margin: 0 15px;
    border: none;
    cursor: pointer;

    

}
.mark-attended-box-text{
    width:90%;
    height: 100%;
    margin-top: 20px;
    display:flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.mark-attended-box-text h4 {
    padding: 10px 0!important;
    align-self: flex-start;
}
.mark-attended-box-text button {
    padding: 10px 15px;
    border-radius: 5px;
    align-self: flex-end;
    border: none;
    cursor: pointer;
    background-color: rgb(71, 102, 187);
    color: white;
    margin: 10px 0;

}
.mark-attended-box-text div {
    align-self: flex-end;
    gap: 10px;
    display:flex;

}
.mark-attended-box-text button:hover {
    background-color: rgb(0, 0, 121);
}
.mark-attended-box-text textarea {
    width:100%;
    min-height: 50%;
    border-radius: 5px;
    border: none;
    padding: 10px;
    outline: none;
    resize: none;
    background-color: transparent;
    color: #fafafa;
    border: 2px solid rgb(84, 84, 84);
}
.mark-attended-box-buttons .attended {
    background-color: rgb(71, 187, 80);
    color: white;
}
.mark-attended-box-buttons .attended:hover {
    background-color: rgb(0, 121, 0);
}
.mark-attended-box-buttons .missed {
    background-color: rgba(213, 121, 0, 0.729);
    color: white;
}
.mark-attended-box-buttons .missed:hover {
    background-color: rgba(130, 59, 0, 0.693);
}
.event-container {
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}

.mark-attended-box-buttons button:not(.selected-attended){
    background-color: rgb(155, 155, 155) !important;
    color:rgb(255, 255, 255) !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55) !important;
    opacity: .5;
}
.selected-attended {
    box-shadow: 0px 0px 5px 0px rgba(86, 86, 86, 0.75) !important;
}
@keyframes attended-box {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}