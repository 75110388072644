.footer {
    width:100%;
    background-color: rgb(2, 0, 45);
    height: 100%;
    -webkit-mask-image: -webkit-gradient(
        linear, 
        0 0, 
        0 20%, 
        from(rgba(20, 20, 20, 0.055)), 
        to(rgb(1, 0, 19))
    );
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    flex-direction: column;
    gap:30px;
}
.footer p {
    color: #d0d0d0;
    font-weight: 600;
}
.footer span {
    color:#b9b9b9;
}

@media only screen and (max-width: 620px) {
    .footer span {
        text-align: center;
        font-size: .7rem;
        color: rgb(119, 119, 119);
    }
    .footer p {
        color: rgb(139, 139, 139);
        font-size: .8rem;
        text-align: center;
    }
}