* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container-loader{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    margin: 0;
    --x: calc(var(--posX, 0) * 1px);
    --y: calc(var(--posY, 0) * 1px);
    background-image: 
      linear-gradient(115deg, rgb(211 255 215), rgb(0 0 0)), 
      radial-gradient( 90% 100% at calc( 50% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(77, 0, 165)), 
      radial-gradient(100% 100% at calc( 80% - var(--x)) calc( 0% - var(--y)), rgb(0, 0, 0), rgb(75, 128, 153)), 
      radial-gradient(150% 210% at calc(100% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(126, 108, 200)), 
      radial-gradient(100% 100% at calc(100% - var(--x)) calc(30% - var(--y)), rgb(0, 0, 0), rgb(120, 0, 184)), 
      linear-gradient(60deg, rgb(0, 17, 130), rgb(72, 0, 255));
    background-blend-mode: overlay, overlay, difference, difference, difference, normal;
    position: relative;
    z-index: 0;
    display:flex;

}

.loader {
    position: relative;
    display: flex;
    animation: fadeIn 1s linear;
}

.loader span {
    position: relative;
    width: 250px;
    height: 250px;
    background-color: #eaeef0;
    border: 6px solid #eaeef0;
    border-radius: 50%;

    overflow: hidden;
}

.loader span::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 50%;

}

.loader span::after {
    content: "";
    position: absolute;
    inset: 40px;
    background: #eaeef0;
    border: 3px solid #eaeef0;
    border-radius: 50%;
    box-shadow: -8px -8px 25px rgba(255, 255, 255, 1),
                8px 8px 25px rgba(0, 0, 0, 0.25),
                inset 3px 3px 10px rgba(0, 0, 0, 0.15),
                inset -1px -1px 15px rgba(255, 255, 255, 1);
}

.loader span i {
    position: absolute;
    inset: 0;
    border-radius: 50%;
    background: linear-gradient(#42abff, #ff4f8b, #ffeb3b);
    animation: animateLoader 1s linear infinite;
}
@keyframes fadeIn {
    0% {
        opacity: 0;
        margin-top: 100px;

    }
    100% {
        opacity: 1;
        margin-top: 0px;
    }
    
}

@keyframes animateLoader {
    0%
    {
        transform: rotate(0deg);
    }
    100%
    {
        transform: rotate(360deg);
    }
}

.rocket-loader {
    position: absolute;
    inset: 50px;
    z-index: 10;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}

.rocket-loader .fa-rocket-loader {
    position: absolute;
    top: 35%;
    color: #ff518c;
    font-size: 3.5em;
    -webkit-text-stroke: 2px #000;
    animation: animateRocket 0.2s linear infinite;
}

@keyframes animateRocket {
    0%,100%
    {
        transform: translate(0,0) rotate(-45deg);
    }
    50%
    {
        transform: translate(0,3px) rotate(-45deg);
    }
}
