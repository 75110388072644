
.alertbox-container{
    width:100%;
    height:100%;
    position:absolute;
    right:0;
    background-color: rgba(255, 255, 255, 0);
    box-shadow: 0 0 1px rgba(0,0,0,.1),0 5px 24px rgba(0, 0, 0, 0.3);
    display:flex;
    flex-direction: row;
    align-items: center;
    animation: 0.2s scheduleside ease-in;
    z-index: 1300;
    

}
.alertbox-exit {
    width: 75%;
    height: 100%;
    background-color: transparent;

}
.alertbox-content {
    border-top: 5px solid #e4c49b;
    width:25%;
    height: 100%;
    min-width: 350px;
    overflow-y: scroll;
    background-color: rgb(255, 255, 255);
}
.message-alert-header {
    margin-top: 5px;
    display:flex;
    flex-direction: row;
    align-items: center;
    align-self: flex-start;
    border-radius: 5px;;
    
}
.message-alert-header p {
    margin: 0;
    padding: 0 10px 0 0;
    color: rgb(205, 205, 205);
}
.message-alert-header .svg-inline--fa{
    color: rgb(205, 205, 205);
    height: 1.2rem;
    padding:6px 10px 6px 10px;
    border-radius: 5px;
}
.message-alert-header:hover .svg-inline--fa, .message-alert-header:hover p{
    cursor:pointer;
    color:rgb(118, 118, 118)
}
.message-alert-header:hover {
    background-color: aliceblue;
}