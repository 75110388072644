.first-time-container, .invited-container {
    width:100%;
    height:100vh;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    --x: calc(var(--posX, 0) * 1px);
    --y: calc(var(--posY, 0) * 1px);
    background-image: 
      linear-gradient(115deg, rgb(211 255 215), rgb(0 0 0)), 
      radial-gradient( 90% 100% at calc( 50% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(77, 0, 165)), 
      radial-gradient(100% 100% at calc( 80% - var(--x)) calc( 0% - var(--y)), rgb(0, 0, 0), rgb(75, 128, 153)), 
      radial-gradient(150% 210% at calc(100% + var(--x)) calc( 0% + var(--y)), rgb(0, 0, 0), rgb(126, 108, 200)), 
      radial-gradient(100% 100% at calc(100% - var(--x)) calc(30% - var(--y)), rgb(0, 0, 0), rgb(120, 0, 184)), 
      linear-gradient(60deg, rgb(0, 17, 130), rgb(72, 0, 255));
    background-blend-mode: overlay, overlay, difference, difference, difference, normal;
    position: relative;

}
.first-time-container h1 {
    color: rgb(233, 233, 233);
    font-weight: 700;
    font-size: 2rem;
}
.first-time-container h2 {
    color: rgb(234, 234, 234);
    font-weight: 400;
    margin: 20px 0 50px 0px;
    align-self: center;
    font-size: 1.2rem;
}
.additional-email span{
  position:absolute;
  top: 10px;
  left: -60px;
  display:none;
  width: 20px;
  height: 20px;
  background-color: #eaeaea;
  transform: rotate(45deg);
}
.additional-email {
  margin:0;
}
.additional-email input:focus ~ p, .additional-email input:focus ~ span {
  display: block;
}

.additional-email p {
  display: none;
  border-radius: 5px;
    position:absolute;
    width:150px;
    top: -15px;
    left:-200px;
    background-color: #eaeaea;
    color: rgb(78, 78, 78);
    font-weight: 400;
    padding: 6px;
    align-self: center;
    font-size: .8rem;

}
.first-time-header {
    display:flex;
    width:100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    z-index: 1000;
}
.tutor-button {
  padding: 15px 30px;
  margin: 20px 30px 0px 0px ;
  border-radius: 10px;
  border: 1px #a4a4a4 solid;
  background-color: #460087;
  color: rgb(201, 201, 201);
}
.tutor-button:hover {
    background-color: #36005a;
    cursor: pointer;
    border: 1px #ffffff solid;
    color: rgb(255, 255, 255);
}
.first-time-header h1 {
    margin:20px 0px 40px 30px;
    border-bottom: 3px solid #ad6fe7;
    background: linear-gradient(93.51deg, #ad6fe7 2.84%, #3081ED 99.18%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 1.6rem;
}
.first-time-container section {
    display:flex;
    flex-direction: row;
    width:100%;
    justify-content: center;
    align-items: center;
    gap:10px;
    height: 50%;
}
.choice-button {
    padding: 40px;
    margin: 30px;
    display:flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
    border:none;
    z-index: 1000;
}
.button-s:hover {
    background-color: #4a77ff7c;
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
    cursor: pointer;
}
.button-p:hover {
    background-color: #ff7d1a85;
    box-shadow: 0 5px 5px rgba(255, 255, 255, 0.2);
    cursor: pointer;
}
.button-t:hover {
    background-color: #ffa01a78;
    box-shadow: 0 5px 15px rgba(255, 255, 255, 0.2);
    cursor: pointer;
}

.choice-button h3 {
    font-size: 1.2rem;
    font-weight: 500;
    color: #c6c6c6;
}

.button-s {
    background-color: #7497ff33;
}
.button-p {
    background-color: #cf680041;
}
.button-t {
    background-color: #ffb61a45;
}

.backg{
    position:relative;
    height:400px;
    width:400px;
    border-radius:50%;
    overflow: hidden;
    background-color:rgba(37, 47, 77, 0.499);
    transform:scale(0.7);
    }
  
.parent-out {
  animation: parent-out .7s ease-in-out;
  animation-fill-mode: forwards;
}

.off {
  display: none;
}
.tutor-form {
  width: 60%;
  height: 100%;
  background-color: #7373738e;
  border-radius: 20px;
  display:flex;
  flex-direction: column;
  padding: 20px 40px;
  z-index: 1002;
}
.tutor-form h2 {
  font-size: 1.4rem;
  color: #9cb5ff;
  margin: 0;
  padding: 0;
  text-align: center;
  padding-top: 20px;
  padding-left: 20px;
}
form textarea{
  width: 100%;
  border: none;
  border-radius: 10px;

  padding: 10px;
  
  color: #ececec;
  background-color: #c2c2c28e;
  resize: none;
  outline: none;
}
.tutor-form textarea::placeholder {
  color: #acacac;
}
.tutor-form button {
  border: none;
  border-radius: 10px;
  margin: 10px;
  padding: 15px 30px;
  align-self: center;
  font-size: 1rem;
  color: #e7e7e7;
  background-color: #3c70ff8e;
  outline: none;
  cursor: pointer;
}
.tutor-form button:hover {
  background-color: #3c70ff;

}
.student-out {
  animation: student-out .7s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes parent-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(200%);
    display: none;
  }
}

@keyframes student-out {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200%);
    display: none;
  }
}


.student-form-buttons{
  display:inline-block;
  margin: 0px 0px 15px 0px;

}
.form-submit-button {
  align-self: flex-end;
  padding: 10px 20px;
  outline: none;
  border: none;
  margin: 10px 0px 0px 0px;
  border-radius: 10px;
  background-color: #00b4067e;
  color: #c8c8c8;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
}
.form-submit-button:hover {
  background-color: #00b406;
  color: #fafafa;
  cursor: pointer;
}

.student-form-buttons button {
  width: 150px;
  padding: 10px 20px;
  margin-right:10px ;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 1rem;
  font-weight: 400;
  background-color: #90c0ff67;
  color: #e1e1e1;
  
}
.student-form-buttons button:hover {
  background-color: #73b0ff;
  cursor: pointer;
}
.form-button-selected {
  background-color: #ff942aae !important;
}
.student-form-submit {
  position:absolute;
  bottom: 100px;
  right: 30%;
  padding: 20px 50px;
  outline: none;
  border: none;
  border-radius: 20px;
  background-color: #00b4067e;
  color: #dddddd;
  font-size: 1rem;
  font-weight: 600;
}
.student-form-submit:hover {
  background-color: #00b406b0;
  cursor: pointer;
  margin-bottom: 5px;
  box-shadow: 0px 2px 3px #d9d9d9;
  transition: all .2s ease-in-out;
}

form input::placeholder, form input:-ms-input-placeholder, form input::-ms-input-placeholder {
  color: rgb(255, 255, 255);
  opacity: 1; /* Firefox */
}
.form{
  position:relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

}
.form form {
  padding: 20px 40px;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 20px;
  background-color: #c4c4c459;
}
form span {
  display:flex;
  flex-direction: column
}
form div {
  position:relative;
  display:flex;
  flex-direction: row;
  justify-content: center;
  color:#fafafa;
  align-items: center;
  gap: 25px;
  margin: 10px 0px 20px 0px;
}
form input {
  border: none;
  padding: 10px;
  background-color: #ffffff59;
  border-radius: 10px;
  color: #fafafa;
  font-weight: 400;
  font-size: 1rem;
  outline: none;
}
form select {
  border: none;
  padding: 10px;
  background-color: #ffffff59;
  border-radius: 10px;
  color: #fafafa;
  font-weight: 400;
  font-size: .8rem;
  outline: none;
}
form label {
  font-size: .8rem;
  color: #b1b1b1;
  margin-bottom: 5px;
  text-align: start;
}
.form .svg-inline--fa {
  position:absolute;
  top: 15px;
  left: 20px;
  color: #cfcfcf;
  font-size: 1.6rem;

}
.form .svg-inline--fa:hover {
  cursor: pointer;
  color: #ffffff;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(176, 176, 176);
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: rgb(176, 176, 176);
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: rgb(176, 176, 176);
}

@media screen and (max-width: 965px) {
}
  
