
.carousel {
  z-index: 100;
  margin-left: 2.5%;
  margin-right: 2.5%;
}

ul.slides {
  display: block;
  position: relative;
  height: 600px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style: none;
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

ul.slides input {
  display: none; 
}


.slide-container { 
  display: block; 
}

.slide-image {
  background-color: rgba(0, 255, 255, 0);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  opacity: 0;
  transition: all .7s ease-in-out;
  display:flex;
  justify-content: center;
  align-items: center;

}
.slide-content {
  width: 100%;
  height:70%;
  background-color: rgba(255, 200, 127, 0.628);
  border-radius: 20px;
  display:flex;
  align-items: center;
  justify-content: flex-end;
}
.slide-content-textbox {
  width: 50%;
  margin-right:60px;
}
.slide-content-textbox h4 {
  font-size: 1.5rem;
  color: #dedede;
  font-weight: 300;
}
.slide-content-textbox p {
  margin-top: 30px;
  font-size: 1rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
}
.slide-photo {
  position:absolute;
  left: 40px;
  border-radius: 15px;
  height:100%;
  width: 35%;
  object-fit: cover;
  box-shadow: 5px 5px 25px 5px rgba(0, 0, 0, 0.613);
}


.carousel-controls {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  font-size: 100px;
  line-height: 600px;
  color: #fff;
}

.carousel-controls label {
  display: none;
  position: absolute;
  padding: 0 10px;
  transition: opacity .2s;
  cursor: pointer;
}

.slide-image:hover + .carousel-controls label{
  opacity: 0.5;
}

.carousel-controls label:hover {
  opacity: 1;
}

.carousel-controls .prev-slide {
  width: 49%;
  text-align: left;
  left: 0;
}

.carousel-controls .next-slide {
  width: 49%;
  text-align: right;
  right: 0;
}

.carousel-dots {
  position: absolute;
  left: 100px;
  right: 0;
  bottom: 20px;
  z-index: 999;
  text-align: center;
}

.carousel-dots .carousel-dot {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.5;
  margin: 10px;
}

input:checked + .slide-container .slide-image {
  opacity: 1;
  transform: scale(1);
  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .carousel-controls label {
   display: block; 
}

input#img-1:checked ~ .carousel-dots label#img-dot-1,
input#img-2:checked ~ .carousel-dots label#img-dot-2,
input#img-3:checked ~ .carousel-dots label#img-dot-3,
input#img-4:checked ~ .carousel-dots label#img-dot-4,
input#img-5:checked ~ .carousel-dots label#img-dot-5,
input#img-6:checked ~ .carousel-dots label#img-dot-6,
input#img-7:checked ~ .carousel-dots label#img-dot-6  {
opacity: 1;
}


input:checked + .slide-container .nav label { display: block; }
