.description-box p {
    font-size: 18px;
    font-weight: 300;
    color: rgb(176, 176, 176);
}

.description-box {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 85%;
    position: relative;
    margin-top: 30px;
    z-index: 100;
}

.description-box-container {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.description-box  button {
    margin-top: 30px;
    padding: 15px 30px;
    border-radius: 5px;
    border:2px #c8c8c8 solid;
    outline: none;
    color:#c8c8c8;
    font-size: 1rem;
    font-weight: 500;
    background-color: transparent;
    cursor: pointer;
}
.description-button-box {
    display:flex;
    flex-direction: row;
    gap:20px;
}
.description-button-box  button:hover {
    background-color: rgba(255, 255, 255, 0.11);
    color:#fafafa;
    border:2px #ffffff solid;
    box-shadow: 0px 5px 5px 0px rgb(90, 90, 90);
}

@media only screen and (max-width: 600px) {
    .wrapper{
        width: 100% !important;
    }
    .titlebox_container h1, .titlebox_container span {
        font-size: 12vw !important;
        letter-spacing: -1px !important;
        margin: 0 8px 0 0 !important;
    }
    .titlebox_container {
        width: auto !important;
        flex-direction: column !important;
    }
    .description-box p {
        font-size: 3.3vw !important;
        padding : 0px 15px;
    }

}